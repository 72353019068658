@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    h1 {
        @apply text-2xl font-bold;
    }
    h2 {
        @apply text-xl font-bold;
    }
    h3 {
        @apply text-lg font-bold;
    }
    button:disabled {
        cursor: not-allowed
    }
}